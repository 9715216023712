exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-congrats-jsx": () => import("./../../../src/pages/congrats.jsx" /* webpackChunkName: "component---src-pages-congrats-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-us-success-index-jsx": () => import("./../../../src/pages/contact-us-success/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-success-index-jsx" */),
  "component---src-pages-cost-index-jsx": () => import("./../../../src/pages/cost/index.jsx" /* webpackChunkName: "component---src-pages-cost-index-jsx" */),
  "component---src-pages-download-savings-offer-2-jsx": () => import("./../../../src/pages/download-savings-offer-2.jsx" /* webpackChunkName: "component---src-pages-download-savings-offer-2-jsx" */),
  "component---src-pages-download-savings-offer-jsx": () => import("./../../../src/pages/download-savings-offer.jsx" /* webpackChunkName: "component---src-pages-download-savings-offer-jsx" */),
  "component---src-pages-form-submission-error-jsx": () => import("./../../../src/pages/form-submission-error.jsx" /* webpackChunkName: "component---src-pages-form-submission-error-jsx" */),
  "component---src-pages-how-to-use-index-jsx": () => import("./../../../src/pages/how-to-use/index.jsx" /* webpackChunkName: "component---src-pages-how-to-use-index-jsx" */),
  "component---src-pages-how-tyrvaya-works-index-jsx": () => import("./../../../src/pages/how-tyrvaya-works/index.jsx" /* webpackChunkName: "component---src-pages-how-tyrvaya-works-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-results-index-jsx": () => import("./../../../src/pages/results/index.jsx" /* webpackChunkName: "component---src-pages-results-index-jsx" */),
  "component---src-pages-savings-and-support-index-jsx": () => import("./../../../src/pages/savings-and-support/index.jsx" /* webpackChunkName: "component---src-pages-savings-and-support-index-jsx" */),
  "component---src-pages-sign-up-to-learn-more-jsx": () => import("./../../../src/pages/sign-up-to-learn-more.jsx" /* webpackChunkName: "component---src-pages-sign-up-to-learn-more-jsx" */),
  "component---src-pages-sign-up-to-save-jsx": () => import("./../../../src/pages/sign-up-to-save.jsx" /* webpackChunkName: "component---src-pages-sign-up-to-save-jsx" */),
  "component---src-pages-talk-to-your-doctor-index-jsx": () => import("./../../../src/pages/talk-to-your-doctor/index.jsx" /* webpackChunkName: "component---src-pages-talk-to-your-doctor-index-jsx" */),
  "component---src-pages-terms-and-conditions-index-jsx": () => import("./../../../src/pages/terms-and-conditions/index.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-pages-unsubscribe-successful-index-jsx": () => import("./../../../src/pages/unsubscribe-successful/index.jsx" /* webpackChunkName: "component---src-pages-unsubscribe-successful-index-jsx" */),
  "component---src-pages-video-transcript-crystals-story-jsx": () => import("./../../../src/pages/video-transcript/crystals-story.jsx" /* webpackChunkName: "component---src-pages-video-transcript-crystals-story-jsx" */),
  "component---src-pages-video-transcript-eileens-story-jsx": () => import("./../../../src/pages/video-transcript/eileens-story.jsx" /* webpackChunkName: "component---src-pages-video-transcript-eileens-story-jsx" */),
  "component---src-pages-video-transcript-how-to-use-tyrvaya-jsx": () => import("./../../../src/pages/video-transcript/how-to-use-tyrvaya.jsx" /* webpackChunkName: "component---src-pages-video-transcript-how-to-use-tyrvaya-jsx" */),
  "component---src-pages-video-transcript-how-tyrvaya-works-jsx": () => import("./../../../src/pages/video-transcript/how-tyrvaya-works.jsx" /* webpackChunkName: "component---src-pages-video-transcript-how-tyrvaya-works-jsx" */),
  "component---src-pages-video-transcript-michaels-story-jsx": () => import("./../../../src/pages/video-transcript/michaels-story.jsx" /* webpackChunkName: "component---src-pages-video-transcript-michaels-story-jsx" */),
  "component---src-pages-why-real-tears-index-jsx": () => import("./../../../src/pages/why-real-tears/index.jsx" /* webpackChunkName: "component---src-pages-why-real-tears-index-jsx" */)
}

