import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

/* Set Global States here. */
const defaultState = {
    exitModalOpen: false,
    showExitModal: () => {},
    externalUrl: null,
    setExternalUrl: () => {},
    modalTrigger: null,
    setModalTrigger: () => {},
    textZoom: false,
    setTextZoom: () => {},
    currentVideo: null,
    setcurrentVideo: () => {},
};

const AppContext = createContext(defaultState);

const AppProvider = ({ children }) => {
    const [exitModalOpen, showExitModal] = useState(defaultState.modalTrigger);
    const [externalUrl, setExternalUrl] = useState(defaultState.externalUrl);
    const [modalTrigger, setModalTrigger] = useState(defaultState.modalTrigger);
    const [textZoom, setTextZoom] = useState(defaultState.textZoom);
    const [currentVideo, setcurrentVideo] = useState(defaultState.currentVideo);

    const store = {
        exitModalOpen,
        showExitModal,
        externalUrl,
        setExternalUrl,
        modalTrigger,
        setModalTrigger,
        textZoom,
        setTextZoom,
        currentVideo,
        setcurrentVideo,
    };

    return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
    children: PropTypes.node,
};

export default AppContext;

export { AppProvider };
