import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AppProvider } from './src/context';
import GTM from '@utils/GTM';

import '@styles/main.css';

/**
 * Gets a querystring from the url
 * @param  {string} key      The querystring key
 * @param  {string} default_ The default if there is none setCookie
 * @return {string}          The value of the querystring referenced by the key
 */
function getQuerystring(key, default_) {
    if (default_ === null) default_ = '';

    key = key.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');

    const regex = new RegExp('[\\?&]' + key + '=([^&#]*)');
    const qs = regex.exec(window.location.href);

    if (qs === null) return default_;

    return qs[1];
}

if (getQuerystring('utm_source') === 'IFU_Ref_Guide') {
    document.location = '/how-to-use/#video';
} else if (document.location.pathname === '/01' && getQuerystring('utm_source') === 'tent_card') {
    document.location = '/?utm_source=tent_card&utm_medium=print&utm_campaign=v1';
} else if (
    document.location.pathname === '/02' &&
    getQuerystring('utm_source') === 'patient_brouchure'
) {
    document.location =
        '/how-to-use/?utm_source=patient_brouchure&utm_medium=print&utm_campaign=v1#video';
} else if (
    document.location.pathname === '/03' &&
    getQuerystring('utm_source') === 'patient_brouchure'
) {
    document.location =
        '/savings-and-support/?utm_source=patient_brouchure&utm_medium=print&utm_campaign=v1';
} else if (document.location.pathname === '/04') {
    document.location = '/?utm_source=tent-card-2&utm_medium=print&utm_campaign=OP-TYR-001395';
} else if (document.location.pathname === '/05') {
    document.location = '/?utm_source=ifuposter&utm_medium=print&utm_campaign=OP-TYR-001286';
}

if (
    document.location.pathname === '/talk-to-your-doctor' &&
    document.location.hash.includes('#download-guide') &&
    getQuerystring('utm_source') === 'patient_brochure' &&
    getQuerystring('utm_medium') === 'QRCode' &&
    getQuerystring('utm_term') === 'pg12' &&
    getQuerystring('utm_content') === 'see_doctor_discussion_guide' &&
    getQuerystring('utm_campaign') === '2023_op_tyrvaya'
) {
    document.location =
        '/talk-to-your-doctor?utm_source=patient_brochure&utm_medium=QRCode&utm_term=pg12&utm_content=see_doctor_discussion_guide&utm_campaign=2023_op_tyrvaya#download-guide';
}

if (
    document.location.pathname === '/talk-to-your-doctor' &&
    document.location.hash.includes('#patient-testimonials') &&
    getQuerystring('utm_source') === 'patient_brochure' &&
    getQuerystring('utm_medium') === 'QRCode' &&
    getQuerystring('utm_term') === 'pg6' &&
    getQuerystring('utm_content') === 'see_how_real_patients_found_dry_eye_relief' &&
    getQuerystring('utm_campaign') === '2023_op_tyrvaya'
) {
    document.location =
        '/talk-to-your-doctor?utm_source=patient_brochure&utm_medium=QRCode&utm_term=pg6&utm_content=see_how_real_patients_found_dry_eye_relief&utm_campaign=2023_op_tyrvaya#patient-testimonials';
}

if (
    document.location.pathname === '/how-to-use' &&
    document.location.hash.includes('#video') &&
    getQuerystring('utm_source') === 'patient_brochure' &&
    getQuerystring('utm_medium') === 'QRCode' &&
    getQuerystring('utm_term') === 'pg9' &&
    getQuerystring('utm_content') === 'see_instructed_video' &&
    getQuerystring('utm_campaign') === '2023_op_tyrvaya'
) {
    document.location =
        '/how-to-use?utm_source=patient_brochure&utm_medium=QRCode&utm_term=pg9&utm_content=see_instructed_video&utm_campaign=2023_op_tyrvaya#video';
}

export const onRouteUpdate = ({ location }) => {
    const { pathname, hash } = location;

    if (pathname.match(/^\/cost\/?$/)) {
        document.location.href = '/savings-and-support#cost';
    }

    if (pathname === '/savings-and-support' && hash === '#brochure') {
        document.location.href = '/savings-and-support/#team-tyrvaya';
    }

    if (pathname === '/savings-and-support' && hash === '#get-offer') {
        document.location.href = '/sign-up-to-save';
    }

    if (pathname === '/' && hash === '#crystal-video') {
        document.location.href = '/talk-to-your-doctor/#crystal';
    }
    if (pathname === '/' && hash === '#eileen-video') {
        document.location.href = '/talk-to-your-doctor/#eileen';
    }
    if (pathname === '/' && hash === '#michael-video') {
        document.location.href = '/talk-to-your-doctor/#michael';
    }

    GTM.reset();
};

export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_SITE_KEY_CONTACT_US}>
            <AppProvider>{element}</AppProvider>
        </GoogleReCaptchaProvider>
    );
};
